import {
  PROJECTS_LOADING,
  PROJECTS_ERROR,
  GET_PROJECTS,
  CREATE_PROJECT,
  PROJECT_LOADING,
  PROJECT_ERROR,
  GET_PROJECT,
} from "./../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const singleProjectInitial = {
  loading: false,
  data: {},
  error: null,
};

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
  singleProject: singleProjectInitial,
};

const projectsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PROJECTS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(PROJECTS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_PROJECTS, (state, { payload }) => {
      state.data = payload?.data;
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(CREATE_PROJECT, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    })
    .addCase(PROJECT_LOADING, (state) => {
      state.singleProject.loading = true;
    })
    .addCase(PROJECT_ERROR, (state, { payload }) => {
      state.singleProject.error = payload;
      state.singleProject.loading = false;
    })
    .addCase(GET_PROJECT, (state, { payload }) => {
      state.singleProject.data = payload?.data;
      state.singleProject.loading = false;
    });
});

export default projectsReducer;
