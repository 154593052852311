import { configureStore } from "@reduxjs/toolkit";
import {
  auth,
  app,
  projects,
  requests,
  offers,
  bills,
  categories,
} from "./reducers";

const store = configureStore({
  reducer: {
    auth,
    app,
    projects,
    requests,
    offers,
    bills,
    categories,
  },

  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
