import {
  AppBar,
  Avatar,
  Box,
  Button,
  Toolbar,
  Typography,
} from "@mui/material";
import { logoutAction } from "../store/actions/auth";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logo } from "../assets";

export default function NavBar() {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutAction());
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        bgcolor: "#FFF",
        boxShadow: "0px 4px 18px #E5E5E5",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Box component={Link} to={"/"}>
            <img
              src={logo}
              alt=""
              style={{ cursor: "pointer", maxHeight: "40px" }}
            />
          </Box>
          <Typography variant="subtitle1" sx={{ color: "#202020" }}>
            Admin
          </Typography>
        </Box>

        <Button onClick={handleLogout}>
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            Logout
          </Typography>
        </Button>
      </Toolbar>
    </AppBar>
  );
}
