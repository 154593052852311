import {
  REQUESTS_LOADING,
  REQUESTS_ERROR,
  GET_REQUESTS,
  CREATE_REQUEST,
  DELETE_REQUEST,
  REQUEST_LOADING,
  REQUEST_ERROR,
  GET_REQUEST,
  CREATE_OFFER,
  UPDATE_REQUEST,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const singleRequestInitial = {
  loading: false,
  data: {},
  error: null,
};

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
  singleRequest: singleRequestInitial,
};

const requestsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(REQUESTS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(REQUESTS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_REQUESTS, (state, { payload }) => {
      state.data = payload?.data;
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(CREATE_REQUEST, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    })
    .addCase(DELETE_REQUEST, (state, { payload }) => {
      const updatedData = state.data.filter((item) => item.id !== payload.id);
      state.data = updatedData;
    })
    .addCase(REQUEST_LOADING, (state) => {
      state.singleRequest.loading = true;
    })
    .addCase(REQUEST_ERROR, (state, { payload }) => {
      state.singleRequest.error = payload;
      state.singleRequest.loading = false;
    })
    .addCase(GET_REQUEST, (state, { payload }) => {
      state.singleRequest.data = payload?.data;
      state.singleRequest.loading = false;
    })
    .addCase(CREATE_OFFER, (state, { payload }) => {
      state.singleRequest.data.offer = payload;
    })
    .addCase(UPDATE_REQUEST, (state, { payload }) => {
      const updatedData = payload?.request;
      const updatedIndex = state.data.findIndex(
        (item) => item.id === updatedData.id
      );
      state.data[updatedIndex] = {
        ...state.data[updatedIndex],
        ...updatedData,
      };
    });
});

export default requestsReducer;
